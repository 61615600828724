import React from 'react';
import SEOHead from './components/SEOHead';
import PDFRedactionComponent from './components/PDFRedactionComponent';
import SEOComponent from './components/SEOComponent';

function App() {
  return (
    <div className="App">
      <SEOHead />
      <header className="bg-gray-100 py-6">
        <div className="container mx-auto px-4">
          
          <h1 className="text-4xl font-bold text-gray-800"> ✨📄✂️ ai-redact.com 🔒</h1>
        </div>
      </header>
      <main>
        <section className="py-12 bg-white">
          <div className="container mx-auto px-4">
            <PDFRedactionComponent />
          </div>
        </section>
        <section className="py-12 bg-gray-50">
          <SEOComponent />
        </section>
      </main>
      <footer className="bg-gray-800 text-white py-8">
        <div className="container mx-auto px-4">
          <div className="grid md:grid-cols-3 gap-8 mb-8">
            <div className="text-left">
              <h4 className="text-xl font-bold mb-4">Contact Us</h4>
              <p className="mb-2">SOKT Technologies Private Limited</p>
              <p className="mb-2">91 SpringBoard Business Hub,</p>
              <p className="mb-2">1st Floor, Gopala Krishna Complex,</p>
              <p className="mb-2">45/3 Residency Road,</p>
              <p className="mb-2">Bangalore - 560025, Karnataka</p>
              <p className="mb-2">India</p>
            </div>
            
            <div className="text-left">
              <h4 className="text-xl font-bold mb-4">Contact Information</h4>
              <p className="mb-2">Phone: +91 80-4680-9825</p>
              <p className="mb-2">Email: support@ai-redact.com</p>
              <p className="mb-2">Sales: sales@ai-redact.com</p>
            </div>
            
            <div className="text-left">
              <h4 className="text-xl font-bold mb-4">Legal</h4>
              <p><a href="/privacy-policy.html" className="hover:underline">Privacy Policy</a></p>
              <p><a href="/terms-of-service.html" className="hover:underline">Terms of Service</a></p>
              <p><a href="/return-policy.html" className="hover:underline">Return Policy</a></p>
            </div>
          </div>
          <div className="text-center pt-4 border-t border-gray-700">
            <p>&copy; 2024 SOKT Technologies Private Limited. All rights reserved.</p>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default App;