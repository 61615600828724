import React from 'react';

const SEOComponent = () => {
  return (
    <div className="max-w-4xl mx-auto px-4 text-gray-800">
      <h2 className="text-3xl font-bold mb-6">Secure Your Documents with AI-Powered PDF Redaction</h2>
      
      <section className="mb-8">
        <h3 className="text-2xl font-semibold mb-4">What is PDF Redaction?</h3>
        <p className="mb-4">
          PDF redaction is the process of removing sensitive or confidential information from a document before it is published or shared. Our intelligent PDF redaction tool uses advanced AI technology to automatically identify and obscure potentially sensitive data, ensuring your documents are safe for distribution.
        </p>
      </section>

      <section className="mb-8">
        <h3 className="text-2xl font-semibold mb-4">Why Choose Our AI PDF Redaction Tool?</h3>
        <ul className="list-disc pl-6 space-y-2">
          <li>Advanced AI technology for accurate identification of sensitive information</li>
          <li>User-friendly interface for easy document uploading and redaction</li>
          <li>Customizable redaction options to meet your specific needs</li>
          <li>Secure processing to maintain the confidentiality of your documents</li>
          <li>Quick and efficient redaction process, saving you time and effort</li>
        </ul>
      </section>

      <section className="mb-8">
        <h3 className="text-2xl font-semibold mb-4">Common Use Cases for PDF Redaction</h3>
        <p className="mb-4">
          Our PDF redaction tool is perfect for a wide range of industries and use cases, including:
        </p>
        <ul className="list-disc pl-6 space-y-2">
          <li>Legal firms handling sensitive client information</li>
          <li>Healthcare providers protecting patient data (HIPAA compliance)</li>
          <li>Financial institutions securing customer financial records</li>
          <li>Government agencies preparing documents for public release</li>
          <li>Businesses protecting proprietary information in reports</li>
        </ul>
      </section>

      <section className="mb-12">
        <h2 className="text-3xl font-bold mb-6">How Our PDF Redaction Process Works</h2>
        
        <div className="grid md:grid-cols-2 gap-8 items-center">
          <div className="space-y-6">
            <div className="flex items-start gap-4">
              <div className="bg-blue-600 text-white w-8 h-8 rounded-full flex items-center justify-center flex-shrink-0">1</div>
              <div>
                <h3 className="font-semibold mb-2">Upload Your Document</h3>
                <p className="text-gray-600">Securely upload your PDF to our platform</p>
              </div>
            </div>

            <div className="flex items-start gap-4">
              <div className="bg-blue-600 text-white w-8 h-8 rounded-full flex items-center justify-center flex-shrink-0">2</div>
              <div>
                <h3 className="font-semibold mb-2">AI Detection</h3>
                <p className="text-gray-600">Our AI automatically identifies sensitive information</p>
              </div>
            </div>

            <div className="flex items-start gap-4">
              <div className="bg-blue-600 text-white w-8 h-8 rounded-full flex items-center justify-center flex-shrink-0">3</div>
              <div>
                <h3 className="font-semibold mb-2">Review & Confirm</h3>
                <p className="text-gray-600">Verify the suggested redactions</p>
              </div>
            </div>

            <div className="flex items-start gap-4">
              <div className="bg-blue-600 text-white w-8 h-8 rounded-full flex items-center justify-center flex-shrink-0">4</div>
              <div>
                <h3 className="font-semibold mb-2">Download Secure PDF</h3>
                <p className="text-gray-600">Get your redacted document instantly</p>
              </div>
            </div>
          </div>

          <div className="bg-white rounded-lg shadow-lg p-4">
            <svg 
              width="600" 
              height="800" 
              viewBox="0 0 600 800" 
              xmlns="http://www.w3.org/2000/svg" 
              style={{background: '#fff', fontFamily: 'sans-serif'}}
            >
              {/* Header */}
              <rect x="0" y="0" width="600" height="60" fill="#005A9C"/>
              <text x="50%" y="35" fill="#fff" fontSize="24" fontWeight="bold" textAnchor="middle">
                HEALTH INSURANCE CLAIM FORM
              </text>
              
              {/* General Information Section */}
              <text x="30" y="100" fill="#000" fontWeight="bold" fontSize="16">GENERAL INFORMATION</text>
              
              {/* Horizontal Lines */}
              <line x1="30" y1="120" x2="570" y2="120" stroke="#000" strokeWidth="2"/>
              <line x1="30" y1="230" x2="570" y2="230" stroke="#000" strokeWidth="2"/>
              
              <line x1="300" y1="120" x2="300" y2="180" stroke="#000" strokeWidth="2"/>

              <text x="40" y="145" fill="#000" fontSize="14">LAST NAME</text>
              <text x="310" y="145" fill="#000" fontSize="14">FIRST NAME</text>
              
              <text id="lastname" x="40" y="165" fill="#000" fontSize="14">Doe</text>
              <text id="firstname" x="310" y="165" fill="#000" fontSize="14">Jane</text>

              <line x1="30" y1="210" x2="570" y2="210" stroke="#000" strokeWidth="2"/>
              <line x1="300" y1="180" x2="300" y2="210" stroke="#000" strokeWidth="2"/>

              <text x="40" y="200" fill="#000" fontSize="14">POLICY NUMBER</text>
              <text x="310" y="200" fill="#000" fontSize="14">DATE OF BIRTH</text>

              <text id="policy-number" x="40" y="220" fill="#000" fontSize="14">AB-1234567</text>
              <text id="dob" x="310" y="220" fill="#000" fontSize="14">01/15/1980</text>

              {/* Claim Details Section */}
              <line x1="30" y1="250" x2="570" y2="250" stroke="#000" strokeWidth="2"/>
              <text x="30" y="270" fill="#000" fontSize="16" fontWeight="bold">CLAIM DETAILS</text>
              <line x1="30" y1="290" x2="570" y2="290" stroke="#000" strokeWidth="2"/>

              <text x="40" y="310" fill="#000" fontSize="14">DATE OF SERVICE</text>
              <text x="200" y="310" fill="#000" fontSize="14">PROVIDER NAME</text>
              <text x="400" y="310" fill="#000" fontSize="14">SERVICE DESCRIPTION</text>
              
              <line x1="30" y1="320" x2="570" y2="320" stroke="#000" strokeWidth="2"/>
              
              <text x="40" y="340" fill="#000" fontSize="14">03/02/2024</text>
              <text x="200" y="340" fill="#000" fontSize="14">Dr. Smith</text>
              <text x="400" y="340" fill="#000" fontSize="14">General Consultation</text>
              
              <line x1="30" y1="360" x2="570" y2="360" stroke="#000" strokeWidth="1"/>
              
              <text x="40" y="380" fill="#000" fontSize="14">03/04/2024</text>
              <text x="200" y="380" fill="#000" fontSize="14">ABC Clinic</text>
              <text x="400" y="380" fill="#000" fontSize="14">Blood Test</text>
              
              <line x1="30" y1="400" x2="570" y2="400" stroke="#000" strokeWidth="1"/>
              
              <line x1="30" y1="500" x2="570" y2="500" stroke="#000" strokeWidth="2"/>

              {/* Signature Section */}
              <text x="30" y="520" fill="#000" fontSize="16" fontWeight="bold">SIGNATURE</text>
              <line x1="30" y1="540" x2="570" y2="540" stroke="#000" strokeWidth="2"/>
              
              <text x="40" y="580" fill="#000" fontSize="14">Your Signature:</text>
              <path d="M40,600 C60,610 100,595 120,610 C140,625 160,590 180,610" stroke="#000" fill="none"/>
              <text x="40" y="640" fontSize="14" fill="#000">DATE: 03/02/2024</text>

              {/* Redaction Bars */}
              <g id="redactions" opacity="0">
                <rect x="40" y="153" width="60" height="16" fill="#000"/>
                <rect x="310" y="153" width="60" height="16" fill="#000"/>
                <rect x="40" y="208" width="100" height="16" fill="#000"/>
                <rect x="310" y="208" width="80" height="16" fill="#000"/>
              </g>

              {/* Scan Line */}
              <rect id="scan-line" x="0" y="-40" width="600" height="40" fill="rgba(0,0,0,0.1)">
                <animate 
                  attributeName="y" 
                  from="-40" 
                  to="800" 
                  dur="3s" 
                  fill="remove"
                  begin="0s; scan-up.end" 
                  id="scan-down"
                />
                <animate 
                  attributeName="y" 
                  from="800" 
                  to="-40" 
                  dur="3s" 
                  fill="remove"
                  begin="scan-down.end"
                  id="scan-up"
                />
              </rect>

              <animate 
                xlinkHref="#redactions" 
                attributeName="opacity" 
                values="0; 0; 1; 1; 0"
                dur="6s"
                repeatCount="indefinite"
                begin="0s"
              />

              <animate 
                xlinkHref="#lastname" 
                attributeName="opacity" 
                values="1; 1; 0; 0; 1"
                dur="6s"
                repeatCount="indefinite"
                begin="0s"
              />
              <animate 
                xlinkHref="#firstname" 
                attributeName="opacity" 
                values="1; 1; 0; 0; 1"
                dur="6s"
                repeatCount="indefinite"
                begin="0s"
              />
              <animate 
                xlinkHref="#policy-number" 
                attributeName="opacity" 
                values="1; 1; 0; 0; 1"
                dur="6s"
                repeatCount="indefinite"
                begin="0s"
              />
              <animate 
                xlinkHref="#dob" 
                attributeName="opacity" 
                values="1; 1; 0; 0; 1"
                dur="6s"
                repeatCount="indefinite"
                begin="0s"
              />
            </svg>
          </div>
        </div>
      </section>

      <section className="mb-8">
        <h3 className="text-2xl font-semibold mb-4">Ensuring Document Security and Compliance</h3>
        <p className="mb-4">
          In today's digital age, protecting sensitive information is more crucial than ever. Our AI-powered PDF redaction tool helps you maintain compliance with data protection regulations such as GDPR, CCPA, and HIPAA. By using our tool, you can confidently share documents without the risk of exposing confidential information.
        </p>
      </section>

      <section className="mb-8">
        <h3 className="text-2xl font-semibold mb-4">Our Products & Pricing</h3>
        <div className="grid md:grid-cols-3 gap-6">
          <div className="p-6 bg-white rounded-lg shadow-lg">
            <h4 className="text-xl font-bold mb-2">Basic Plan</h4>
            <p className="text-3xl font-bold mb-4">₹499<span className="text-sm">/month</span></p>
            <ul className="text-left space-y-2">
              <li>✓ Up to 100 PDF redactions</li>
              <li>✓ Basic API access</li>
              <li>✓ Email support</li>
            </ul>
          </div>
          
          <div className="p-6 bg-blue-50 rounded-lg shadow-lg border-2 border-blue-500">
            <h4 className="text-xl font-bold mb-2">Professional</h4>
            <p className="text-3xl font-bold mb-4">₹1,999<span className="text-sm">/month</span></p>
            <ul className="text-left space-y-2">
              <li>✓ Unlimited PDF redactions</li>
              <li>✓ Advanced API access</li>
              <li>✓ Priority support</li>
              <li>✓ Custom redaction rules</li>
            </ul>
          </div>
          
          <div className="p-6 bg-white rounded-lg shadow-lg">
            <h4 className="text-xl font-bold mb-2">Enterprise</h4>
            <p className="text-3xl font-bold mb-4">₹4,999<span className="text-sm">/month</span></p>
            <ul className="text-left space-y-2">
              <li>✓ Unlimited PDF redactions</li>
              <li>✓ Full API access</li>
              <li>✓ 24/7 support</li>
              <li>✓ Custom integration</li>
              <li>✓ On-premise deployment</li>
            </ul>
          </div>
        </div>
      </section>

      <section className="mb-8">
        <h3 className="text-2xl font-semibold mb-4">Get Started with Intelligent PDF Redaction Today</h3>
        <p className="mb-4">
          Experience the power of AI-driven PDF redaction for yourself. Upload your document now and see how easy it is to secure your sensitive information. For enterprise solutions or to discuss custom redaction needs, contact our team at <a href="mailto:hello@ai-redact.com" className="text-blue-600 hover:underline">hello@ai-redact.com</a>.
        </p>
      </section>

      <section className="mb-8">
        <h3 className="text-2xl font-semibold mb-4">Frequently Asked Questions</h3>
        <div className="space-y-4">
          <details className="bg-white p-4 rounded-lg shadow">
            <summary className="font-semibold cursor-pointer">Is my document secure during the redaction process?</summary>
            <p className="mt-2">Yes, we use state-of-the-art encryption and security measures to ensure your documents remain confidential throughout the redaction process. Our servers are regularly audited for security compliance.</p>
          </details>
          <details className="bg-white p-4 rounded-lg shadow">
            <summary className="font-semibold cursor-pointer">Can I customize what information gets redacted?</summary>
            <p className="mt-2">Absolutely! While our AI can automatically detect common types of sensitive information, you have full control over what gets redacted. You can specify custom words, phrases, or patterns to be redacted.</p>
          </details>
          <details className="bg-white p-4 rounded-lg shadow">
            <summary className="font-semibold cursor-pointer">How accurate is the AI in detecting sensitive information?</summary>
            <p className="mt-2">Our AI model has been trained on a vast dataset and can accurately identify most types of sensitive information. However, we always recommend a human review of the suggested redactions before finalizing the document.</p>
          </details>
        </div>
      </section>

      <section className="mb-12">
        <h2 className="text-3xl font-bold mb-6">Understanding PDF Redaction</h2>
        <p className="mb-6 text-lg">
          In today's digital world, where privacy and information security are paramount, the practice of redacting sensitive information in documents is becoming increasingly important. But what exactly does redaction mean, and why is it so crucial?
        </p>
        
        <div className="space-y-8">
          <div>
            <h3 className="text-2xl font-semibold mb-4">Why is Redacting Information Important?</h3>
            <p className="mb-4">
              The redaction of information in documents is a critical step to ensure the privacy and security of personal data. In many industries, especially in legal, healthcare, and public administration, sharing documents without disclosing sensitive information is crucial. Redaction makes specific information within documents unreadable while allowing the remainder of the document to be used for its intended purpose.
            </p>
          </div>

          <div>
            <h3 className="text-2xl font-semibold mb-4">Manual PDF Redaction vs Modern Solutions</h3>
            <div className="grid md:grid-cols-2 gap-6">
              <div className="bg-gray-50 p-6 rounded-lg">
                <h4 className="text-xl font-semibold mb-3">Traditional Method</h4>
                <p>Manually covering sensitive information with black bars in a PDF editor. Time-consuming and prone to human error.</p>
              </div>
              <div className="bg-blue-50 p-6 rounded-lg">
                <h4 className="text-xl font-semibold mb-3">redact-ai Solution</h4>
                <p>Advanced AI-powered automation that instantly identifies and securely redacts sensitive information with perfect accuracy.</p>
              </div>
            </div>
          </div>

          <div>
            <h3 className="text-2xl font-semibold mb-4">PDF Redaction with redact-ai</h3>
            <p className="mb-4">
              Our cutting-edge AI technology automates the entire redaction process. Using advanced machine learning algorithms, redact-ai can detect sensitive text and automatically redact it without human intervention. This process is not only faster but also more secure and reliable than traditional methods.
            </p>
          </div>

          <div>
            <h3 className="text-2xl font-semibold mb-4">Benefits of Automated Redaction</h3>
            <div className="grid md:grid-cols-2 gap-6">
              <ul className="list-disc pl-6 space-y-2">
                <li>Process thousands of pages in minutes, not hours</li>
                <li>Consistent redaction across all documents</li>
                <li>Zero human error in sensitive data identification</li>
                <li>Irreversible redaction for maximum security</li>
              </ul>
              <ul className="list-disc pl-6 space-y-2">
                <li>Automatic compliance with privacy regulations</li>
                <li>Cost-effective document processing</li>
                <li>Simplified workflow integration</li>
                <li>24/7 automated operation</li>
              </ul>
            </div>
          </div>

          <div>
            <h3 className="text-2xl font-semibold mb-4">Industry-Specific Solutions</h3>
            <div className="grid md:grid-cols-2 gap-6">
              <div className="bg-white p-6 rounded-lg shadow">
                <h4 className="text-xl font-semibold mb-3">Legal & Financial</h4>
                <p>Protect client information, intellectual property, and confidential financial data with precision.</p>
              </div>
              <div className="bg-white p-6 rounded-lg shadow">
                <h4 className="text-xl font-semibold mb-3">Healthcare</h4>
                <p>Ensure HIPAA compliance by securely redacting patient information from medical records.</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="mb-12">
        <h2 className="text-3xl font-bold mb-6">5 Ways redact-ai Transforms Your Document Security</h2>
        
        <div className="grid md:grid-cols-2 gap-8">
          <div className="bg-white p-6 rounded-lg shadow-md">
            <h3 className="text-xl font-semibold mb-3">1. Multi-Format Support</h3>
            <p>Process multiple document types including PDF, Word, and Google Docs with our intelligent AI scanning technology.</p>
          </div>

          <div className="bg-white p-6 rounded-lg shadow-md">
            <h3 className="text-xl font-semibold mb-3">2. Compliance-Ready Redaction</h3>
            <p>Automatically align with GDPR, HIPAA, and other regulations. Choose your location for region-specific compliance.</p>
          </div>

          <div className="bg-white p-6 rounded-lg shadow-md">
            <h3 className="text-xl font-semibold mb-3">3. Smart Templates</h3>
            <p>Use pre-configured profiles for healthcare, finance, or legal needs. Create and save custom templates for your team.</p>
          </div>

          <div className="bg-white p-6 rounded-lg shadow-md">
            <h3 className="text-xl font-semibold mb-3">4. Intelligent Labeling</h3>
            <p>Auto-applied labels like 'Email' and 'Name' make redacted documents more comprehensible and organized.</p>
          </div>

          <div className="bg-white p-6 rounded-lg shadow-md">
            <h3 className="text-xl font-semibold mb-3">5. Custom Redaction Rules</h3>
            <p>Specify keywords, phrases, or entire sections for targeted redaction. Perfect for unique organizational needs.</p>
          </div>
        </div>
      </section>

      <section className="mb-12 bg-gray-50 p-8 rounded-lg">
        <h2 className="text-3xl font-bold mb-6">Enterprise-Grade Security</h2>
        <div className="grid md:grid-cols-2 gap-8">
          <div>
            <h3 className="text-xl font-semibold mb-3">Ultimate Document Protection</h3>
            <ul className="list-disc pl-6 space-y-2">
              <li>Industry-standard AES256 encryption</li>
              <li>Secure file deletion after processing</li>
              <li>SSL 2048-bit encryption for transfers</li>
              <li>99%+ accuracy in sensitive data detection</li>
            </ul>
          </div>
          
          <div>
            <h3 className="text-xl font-semibold mb-3">Efficiency Gains</h3>
            <ul className="list-disc pl-6 space-y-2">
              <li>Up to 95% faster than manual redaction</li>
              <li>One-click bulk redaction</li>
              <li>Automated compliance checking</li>
              <li>Real-time processing status</li>
            </ul>
          </div>
        </div>
      </section>

      <section className="mb-12">
        <h2 className="text-3xl font-bold mb-6">Common Types of Protected Information</h2>
        <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
          {[
            "Personal Info",
            "Health Records",
            "Financial Data",
            "Social Security Numbers",
            "Bank Details",
            "Email Addresses",
            "Phone Numbers",
            "Legal Documents"
          ].map((item) => (
            <div key={item} className="bg-blue-50 p-4 rounded-lg text-center">
              <p className="font-medium">{item}</p>
            </div>
          ))}
        </div>
      </section>

      <section className="mb-12 bg-gradient-to-r from-blue-600 to-blue-800 text-white p-8 rounded-lg">
        <h2 className="text-3xl font-bold mb-4">Ready for Enterprise-Level Redaction?</h2>
        <p className="mb-6 text-lg">
          Transform your document security process with redact-ai. Experience the power of AI-driven redaction with enterprise-grade security and compliance.
        </p>
        <div className="flex gap-4">
          <button className="bg-white text-blue-600 px-8 py-3 rounded-lg font-semibold hover:bg-blue-50 transition-colors">
            Get Started
          </button>
          <button className="border-2 border-white text-white px-8 py-3 rounded-lg font-semibold hover:bg-blue-700 transition-colors">
            Contact Sales
          </button>
        </div>
      </section>
    </div>
  );
};

export default SEOComponent;